<link rel=preload async rel="stylesheet" href="/assets/theme/font-awesome/css/font-awesome.css">
<link rel=preload async rel="stylesheet" href="/assets/theme/fonts-new/font-icons.css">
<link rel="preload" async href="assets/styles-external.css">

<button style='display: none;' id='botao-menu-principal' (click)='IrParaHome()'></button>

<div id="app_container">
    <div id="wrapper" style="background-color: #003054 !important; min-height: 660px;">
        <nav id="header" class="app-component navbar-default navbar-static-side navbar-fixed-top" role="navigation"
            style="background-color: #003054 !important; border: 0px !important; position: fixed;">
            <div class="sidebar-collapse">
                <div class="container-logo classe-pc" name='container-1' style="padding: 4px 5px 6px !important;">
                    <a (click)="logout()" title="Voltar">
                        <img alt='img-logo-1' class="classe-pc"
                            src="https://img.listamais.com.br/minha-conta/img/logo-listamais-y.svg"></a>
                </div>
                <ul class="nav metismenu" id="side-menu" *ngIf="variaveisGlobais._logado">
                    <li class="nav-header">
                        <div routerLink="/minha-conta" class="dropdown profile-element" style="cursor: pointer">
                            <span>
                                <img *ngIf="variaveisGlobais._usuario" alt="image"
                                    class="img-circle profile-element--foto"
                                    src="{{variaveisGlobais._usuario.Foto || '../../assets/theme/img/no_picture.jpg'}}">
                            </span>
                            
                            <span class="clear">
                                <span class="block m-t-xs">
                                    <strong class="font-bold username" *ngIf="variaveisGlobais._usuario">
                                        {{variaveisGlobais._usuario.Nome | takeFirstName}}
                                    </strong>
                                </span>
                            </span>                            
                        </div>
                        <div routerLink="/minha-conta" class="logo-element" style="padding: 0px !important;">
                            <a routerLink="/minha-conta">
                                <img routerLink="/minha-conta" *ngIf="variaveisGlobais._usuario" alt="image-mobile"
                                    style='padding:0px; width: 100%;'
                                    src="{{variaveisGlobais._usuario.Foto || '../../assets/theme/img/no_picture.jpg'}}">
                            </a>
                        </div>
                    </li>
                    <div id="idteste2" class="modal" tabindex="-1" role="dialog" data-keyboard="false"
                        [ngStyle]="{'display': display}">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="mySmallModalLabel">Carregando...</h4>
                                </div>
                                <div class="modal-body"> Aguarde um instante... </div>
                            </div>
                        </div>
                    </div>
                    <div [ngStyle]="{'display': display}" class="modal-backdrop fade in modal-stack"></div>

                    <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/" target="_self" title="Inicio">
              <i class="icon-lm-business-04"></i>
              <span class="nav-label">Inicio</span>
              <span class="fa fa-angle-right" style="float: right; line-height: 1.42857; font-size: 14px;">
              </span>
            </a>
          </li> -->
                    <li routerLinkActive="active">
                        <a class='acao-clique-minha-conta' [routerLink]="['minha-conta']" target="_self"
                            title="Minha conta" (click)="GravarRotaMinhaConta()">
                            <i class="icon-lm-user"></i>
                            <span class="nav-label">Meu Perfil</span>
                            <span class="fa fa-angle-right"
                                style="float: right; line-height: 1.42857; font-size: 14px;">
                            </span>
                        </a>
                    </li>
                    <li routerLinkActive="active" id='empresa-botao' (click)="PintaBloco()">
                        <a class='ga-menu-cadastro acao-clique-cadastro-empresa' routerLink="empresa">
                            <i class="icon-lm-business-03"></i>
                            <span class="nav-label">Cadastro de Empresa</span>
                            <span class="fa fa-angle-right"
                                style="float: right; line-height: 1.42857; font-size: 14px;"></span>
                        </a>
                        <a [routerLink]="['empresa']" style='display: none;'></a>
                    </li>
                    <li routerLinkActive="active">
                        <a class='ga-gerenciar-empresas acao-clique-gerenciar-empresa'
                            [routerLink]="['empresa/gerenciar']">
                            <i class="icon-lm-gerenciar-empresas-01"></i>
                            <span class="nav-label">Gerenciar Empresas</span>
                            <span class="fa fa-angle-right"
                                style="float: right; line-height: 1.42857; font-size: 14px;"></span>
                        </a>
                        <a [routerLink]="['/empresa/gerenciar']" style='display: none;'></a>
                    </li>
                    <!-- <li routerLinkActive="active">
            <a routerLink="/locais-favoritos">
              <i class="icon-lm-favoritos-01"></i>
              <span class="nav-label">Locais favoritos</span>
              <span class="fa fa-angle-right" style="float: right; line-height: 1.42857; font-size: 14px;"></span>
            </a>
          </li> -->
                    <li routerLinkActive="active">
                        <a class='ga-avaliacao-enviadas acao-clique-avaliacao' [routerLink]="['avaliacoes']"
                            target="_self" title="Avaliações">
                            <i class="icon-lm-avaliacao"></i>
                            <span class="nav-label">Avaliações</span>
                            <span class="fa fa-angle-right"
                                style="float: right; line-height: 1.42857; font-size: 14px;"></span>
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a [routerLink]="['reportar-erro']"
                            target="_self" title="Relatar um problema">
                            <i class="icon-lm-warning"></i>
                            <span class="nav-label">Relatar Problema</span>
                            <span class="fa fa-angle-right"
                                style="float: right; line-height: 1.42857; font-size: 14px;"></span>
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a class='ga-finalizar-secao' (click)="logoutSiteIntegrado()" title="Finalizar Sessão">
                            <i class="icon-lm-sair"></i>
                            <span class="nav-label">Finalizar Sessão</span>
                            <span class="fa fa-angle-right"
                                style="float: right; line-height: 1.42857; font-size: 14px;"></span>
                        </a>
                    </li>
                    <!--
          <li routerLinkActive="active">
            <a routerLink="/mensagens">
              <i class="icon-lm-enviar"></i>
              <span class="nav-label">Mensagens</span>
              <span class="fa fa-angle-right" style="float: right; line-height: 1.42857; font-size: 14px;"></span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/notificacoes">
              <i class="icon-lm-notificacoes"></i>
              <span class="nav-label">Notificações</span>
              <span class="fa fa-angle-right" style="float: right; line-height: 1.42857; font-size: 14px;"></span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/orcamentos" target="_self" title="Orçamentos">
              <i class="icon-lm-money"></i>
              <span class="nav-label">Orçamentos</span>
              <span class="fa fa-angle-right" style="float: right; line-height: 1.42857; font-size: 14px;"></span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/pagamentos">
              <i class="icon-lm-card-02"></i>
              <span class="nav-label">Pagamentos</span>
              <span class="fa fa-angle-right" style="float: right; line-height: 1.42857; font-size: 14px;"></span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="./providers/form-upload">
              <i class="icon-lm-card-02"></i>
              <span class="nav-label">
              </span>
            </a>
          </li> -->
                    <li>
                        <br />
                        <br />
                        <br />
                        <span class="nav-label">
                        </span>
                    </li>
                </ul>

            </div>
        </nav>

        <div id="page-wrapper" class="gray-bg dashbard-1 fundo-page" style='background-color: #003054 !important;'>
            <div class="row">
                <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0px; min-height: 65px; padding:4px; position: fixed;
          min-width: 100%;">
                    <div class="navbar-header mobile-logo logo-mobile">

                        <a class="navbar-minimalize minimalize-styl-2 btn btn-primary" title="Expandir a tela"
                            *ngIf="variaveisGlobais._logado">
                            <i class="fa fa-bars"></i>
                        </a>
                        <div [ngStyle]="{'width': _styleContainerLogo}" class="container-logo content-container-logo"
                            name='container-2'>
                            <a title="Voltar" class='classe-mobile'>
                                <img [ngStyle]="{'width': _styleLogo}" src="{{_imageLogo}}"></a>
                            <ul class="voltar-ao-site-desktop">
                                <li style='list-style: none;' (click)="voltarAoSite()" title="Voltar ao Site">
                                    <a href="javascript:;" class='ga-voltar-site font-voltar-site'>
                                        Voltar ao Site
                                        <i style='margin-left: 5px;' class="fa fa-external-link" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ul class="nav navbar-top-links navbar-right">
                        <li style='float:right;' (click)="voltarAoSite()" title="Voltar ao Site">
                            <a href="javascript:;" class='ga-voltar-site font-voltar-site'>
                                Voltar ao Site
                                <i class="fa fa-external-link" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="wrapper wrapper-content" [ngStyle]="{'position': !variaveisGlobais._logado ? 'fixed' : 'relative',
      'left': !variaveisGlobais._logado ? '0' : '254', 
      'width': !variaveisGlobais._logado ? '100%' : '100%'}" style='padding-top: 65px;'>
                <div id="mainContent">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
    <div id="right-sidebar" class="animated">
        <div class="sidebar-container">
            <ul class="nav nav-tabs navs-3">
                <li class="active">
                    <a data-toggle="tab" href="#tab-1">
                        Notes
                    </a>
                </li>
                <li>
                    <a data-toggle="tab" href="#tab-2">
                        Projects
                    </a>
                </li>
                <li class="">
                    <a data-toggle="tab" href="#tab-3">
                        <i class="fa fa-gear"></i>
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="tab-1" class="tab-pane active">
                    <div class="sidebar-title">
                        <h3> <i class="fa fa-comments-o"></i> Latest Notes</h3>
                        <small><i class="fa fa-tim"></i> You have 10 new message.</small>
                    </div>
                    <div>
                        <div class="sidebar-message">
                            <a href="#">
                                <div class="pull-left text-center">
                                    <img alt="image" class="img-circle message-avatar" src="">
                                    <div class="m-t-xs">
                                        <i class="fa fa-star text-warning"></i>
                                        <i class="fa fa-star text-warning"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    There are many variations of passages of Lorem Ipsum available.
                                    <br>
                                    <small class="text-muted">Today 4:21 pm</small>
                                </div>
                            </a>
                        </div>
                        <div class="sidebar-message">
                            <a href="#">
                                <div class="pull-left text-center">
                                    <img alt="image" class="img-circle message-avatar" src="">
                                </div>
                                <div class="media-body">
                                    The point of using Lorem Ipsum is that it has a more-or-less normal.
                                    <br>
                                    <small class="text-muted">Yesterday 2:45 pm</small>
                                </div>
                            </a>
                        </div>
                        <div class="sidebar-message">
                            <a href="#">
                                <div class="pull-left text-center">
                                    <img alt="image" class="img-circle message-avatar" src="">
                                    <div class="m-t-xs">
                                        <i class="fa fa-star text-warning"></i>
                                        <i class="fa fa-star text-warning"></i>
                                        <i class="fa fa-star text-warning"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    Mevolved over the years, sometimes by accident, sometimes on purpose (injected
                                    humour and the like).
                                    <br>
                                    <small class="text-muted">Yesterday 1:10 pm</small>
                                </div>
                            </a>
                        </div>
                        <div class="sidebar-message">
                            <a href="#">
                                <div class="pull-left text-center">
                                    <img alt="image" class="img-circle message-avatar" src="">
                                </div>
                                <div class="media-body">
                                    Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the
                                    <br>
                                    <small class="text-muted">Monday 8:37 pm</small>
                                </div>
                            </a>
                        </div>
                        <div class="sidebar-message">
                            <a href="#">
                                <div class="pull-left text-center">
                                    <img alt="image" class="img-circle message-avatar" src="">
                                </div>
                                <div class="media-body">
                                    All the Lorem Ipsum generators on the Internet tend to repeat.
                                    <br>
                                    <small class="text-muted">Today 4:21 pm</small>
                                </div>
                            </a>
                        </div>
                        <div class="sidebar-message">
                            <a href="#">
                                <div class="pull-left text-center">
                                    <img alt="image" class="img-circle message-avatar" src="">
                                </div>
                                <div class="media-body">
                                    Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes
                                    from a line in
                                    section 1.10.32.
                                    <br>
                                    <small class="text-muted">Yesterday 2:45 pm</small>
                                </div>
                            </a>
                        </div>
                        <div class="sidebar-message">
                            <a href="#">
                                <div class="pull-left text-center">
                                    <img alt="image" class="img-circle message-avatar" src="">
                                    <div class="m-t-xs">
                                        <i class="fa fa-star text-warning"></i>
                                        <i class="fa fa-star text-warning"></i>
                                        <i class="fa fa-star text-warning"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below.
                                    <br>
                                    <small class="text-muted">Yesterday 1:10 pm</small>
                                </div>
                            </a>
                        </div>
                        <div class="sidebar-message">
                            <a href="#">
                                <div class="pull-left text-center">
                                    <img alt="image" class="img-circle message-avatar" src="">
                                </div>
                                <div class="media-body">
                                    Uncover many web sites still in their infancy. Various versions have.
                                    <br>
                                    <small class="text-muted">Monday 8:37 pm</small>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="tab-2" class="tab-pane">
                    <div class="sidebar-title">
                        <h3> <i class="fa fa-cube"></i> Latest projects</h3>
                        <small><i class="fa fa-tim"></i> You have 14 projects. 10 not completed.</small>
                    </div>
                    <ul class="sidebar-list">
                        <li>
                            <a href="#">
                                <div class="small pull-right m-t-xs">9 hours ago</div>
                                <h4>Business valuation</h4>
                                It is a long established fact that a reader will be distracted.
                                <div class="small">Completion with: 22%</div>
                                <div class="progress progress-mini">
                                    <div style="width: 22%;" class="progress-bar progress-bar-warning"></div>
                                </div>
                                <div class="small text-muted m-t-xs">Project end: 4:00 pm - 12.06.2014</div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div class="small pull-right m-t-xs">9 hours ago</div>
                                <h4>Contract with Company </h4>
                                Many desktop publishing packages and web page editors.
                                <div class="small">Completion with: 48%</div>
                                <div class="progress progress-mini">
                                    <div style="width: 48%;" class="progress-bar"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div class="small pull-right m-t-xs">9 hours ago</div>
                                <h4>Meeting</h4>
                                By the readable content of a page when looking at its layout.
                                <div class="small">Completion with: 14%</div>
                                <div class="progress progress-mini">
                                    <div style="width: 14%;" class="progress-bar progress-bar-info"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span class="label label-primary pull-right">NEW</span>
                                <h4>The generated</h4>
                                There are many variations of passages of Lorem Ipsum available.
                                <div class="small">Completion with: 22%</div>
                                <div class="small text-muted m-t-xs">Project end: 4:00 pm - 12.06.2014</div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div class="small pull-right m-t-xs">9 hours ago</div>
                                <h4>Business valuation</h4>
                                It is a long established fact that a reader will be distracted.
                                <div class="small">Completion with: 22%</div>
                                <div class="progress progress-mini">
                                    <div style="width: 22%;" class="progress-bar progress-bar-warning"></div>
                                </div>
                                <div class="small text-muted m-t-xs">Project end: 4:00 pm - 12.06.2014</div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div class="small pull-right m-t-xs">9 hours ago</div>
                                <h4>Contract with Company </h4>
                                Many desktop publishing packages and web page editors.
                                <div class="small">Completion with: 48%</div>
                                <div class="progress progress-mini">
                                    <div style="width: 48%;" class="progress-bar"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div class="small pull-right m-t-xs">9 hours ago</div>
                                <h4>Meeting</h4>
                                By the readable content of a page when looking at its layout.
                                <div class="small">Completion with: 14%</div>
                                <div class="progress progress-mini">
                                    <div style="width: 14%;" class="progress-bar progress-bar-info"></div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span class="label label-primary pull-right">NEW</span>
                                <h4>The generated</h4>
                                <!--<div class="small pull-right m-t-xs">9 hours ago</div>-->
                                There are many variations of passages of Lorem Ipsum available.
                                <div class="small">Completion with: 22%</div>
                                <div class="small text-muted m-t-xs">Project end: 4:00 pm - 12.06.2014</div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div id="tab-3" class="tab-pane">
                    <div class="sidebar-title">
                        <h3><i class="fa fa-gears"></i> Settings</h3>
                        <small><i class="fa fa-tim"></i> You have 14 projects. 10 not completed.</small>
                    </div>
                    <div class="setings-item">
                        <span>
                            Show notifications
                        </span>
                        <div class="switch">
                            <div class="onoffswitch">
                                <input type="checkbox" name="collapsemenu" class="onoffswitch-checkbox" id="example">
                                <label class="onoffswitch-label" for="example">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="setings-item">
                        <span>
                            Disable Chat
                        </span>
                        <div class="switch">
                            <div class="onoffswitch">
                                <input type="checkbox" name="collapsemenu" checked class="onoffswitch-checkbox"
                                    id="example2">
                                <label class="onoffswitch-label" for="example2">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="setings-item">
                        <span>
                            Enable history
                        </span>
                        <div class="switch">
                            <div class="onoffswitch">
                                <input type="checkbox" name="collapsemenu" class="onoffswitch-checkbox" id="example3">
                                <label class="onoffswitch-label" for="example3">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="setings-item">
                        <span>
                            Show charts
                        </span>
                        <div class="switch">
                            <div class="onoffswitch">
                                <input type="checkbox" name="collapsemenu" class="onoffswitch-checkbox" id="example4">
                                <label class="onoffswitch-label" for="example4">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="setings-item">
                        <span>
                            Offline users
                        </span>
                        <div class="switch">
                            <div class="onoffswitch">
                                <input type="checkbox" checked name="collapsemenu" class="onoffswitch-checkbox"
                                    id="example5">
                                <label class="onoffswitch-label" for="example5">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="setings-item">
                        <span>
                            Global search
                        </span>
                        <div class="switch">
                            <div class="onoffswitch">
                                <input type="checkbox" checked name="collapsemenu" class="onoffswitch-checkbox"
                                    id="example6">
                                <label class="onoffswitch-label" for="example6">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="setings-item">
                        <span>
                            Update everyday
                        </span>
                        <div class="switch">
                            <div class="onoffswitch">
                                <input type="checkbox" name="collapsemenu" class="onoffswitch-checkbox" id="example7">
                                <label class="onoffswitch-label" for="example7">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="sidebar-content">
                        <h4>Settings</h4>
                        <div class="small">
                            I belive that. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            And typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                            the 1500s.
                            Over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>