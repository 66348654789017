import { Routes } from '@angular/router';
import { NotFoundComponent } from './components/sistema/not-found/not-found.component';
var ɵ0 = { title: "Home" }, ɵ1 = { title: "Avaliações" }, ɵ2 = { title: "Relatar Problema" }, ɵ3 = { title: "Empresas" }, ɵ4 = { title: "Empresas" }, ɵ5 = { title: "..." };
var routes = [
    {
        path: '',
        pathMatch: "full",
        redirectTo: '',
    },
    {
        path: "",
        data: ɵ0,
        loadChildren: './components/home/home.module#HomeModule',
    },
    {
        path: "avaliacoes",
        data: ɵ1,
        loadChildren: './components/avaliacoes/avaliacoes.module#AvaliacoesModule'
    },
    {
        path: "reportar-erro",
        data: ɵ2,
        loadChildren: './components/reportar-erro/reportar-erro.module#ReportarErroModule'
    },
    {
        path: "empresa",
        data: ɵ3,
        loadChildren: './components/empresa/empresa.module#EmpresaModule',
    },
    {
        path: "confirma",
        data: ɵ4,
        loadChildren: './components/confirmacoes/telas-confirmacao.module#TelasConfirmacaoModule',
    },
    {
        path: "**",
        component: NotFoundComponent,
        data: ɵ5
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
