import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthServices } from "./services/auth.service";
import { AuthGuard } from './services/auth-guard.service';

import { NotFoundComponent } from './components/sistema/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: "full",
    redirectTo: '',
  },
  {
    path: "",
    data: { title: "Home" },
    loadChildren: './components/home/home.module#HomeModule',
  },
  {
    path: "avaliacoes",
    data: { title: "Avaliações" },
    loadChildren: './components/avaliacoes/avaliacoes.module#AvaliacoesModule'
  },
  {
    path: "reportar-erro",
    data: { title: "Relatar Problema" },
    loadChildren: './components/reportar-erro/reportar-erro.module#ReportarErroModule'
  },
  {
    path: "empresa",
    data: { title: "Empresas" },
    loadChildren: './components/empresa/empresa.module#EmpresaModule',
  },
  {
    path: "confirma",
    data: { title: "Empresas" },
    loadChildren: './components/confirmacoes/telas-confirmacao.module#TelasConfirmacaoModule',
  },
  {
    path: "**",
    component: NotFoundComponent,
    data: { title: "..." }
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthServices, AuthGuard]
})
export class AppRoutingModule { }
