import { LocalStoreManager } from './local-store-manager.service';
import { DBkeys } from './db-Keys';
import { Utilities } from './utilities';
import { environment } from '../../environments/environment';
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(localStorage) {
        this.localStorage = localStorage;
        this.baseUrl = environment.baseUrl || Utilities.baseUrl();
        this.loginUrl = environment.loginUrl;
        this.fallbackBaseUrl = "https://www.listamais.com.br";
        //***End of defaults***  
        this._language = null;
        this._homeUrl = null;
        this._theme = null;
        this._showDashboardStatistics = null;
        this._showDashboardNotifications = null;
        this._showDashboardTodo = null;
        this._showDashboardBanner = null;
        this.loadLocalChanges();
    }
    ConfigurationService.prototype.loadLocalChanges = function () {
        if (this.localStorage.exists(DBkeys.LANGUAGE)) {
            this._language = this.localStorage.getDataObject(DBkeys.LANGUAGE);
            //this.translationService.changeLanguage(this._language);
        }
        else {
            this.resetLanguage();
        }
        if (this.localStorage.exists(DBkeys.HOME_URL))
            this._homeUrl = this.localStorage.getDataObject(DBkeys.HOME_URL);
        if (this.localStorage.exists(DBkeys.THEME))
            this._theme = this.localStorage.getDataObject(DBkeys.THEME);
        if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_STATISTICS))
            this._showDashboardStatistics = this.localStorage.getDataObject(DBkeys.SHOW_DASHBOARD_STATISTICS);
        if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS))
            this._showDashboardNotifications = this.localStorage.getDataObject(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
        if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_TODO))
            this._showDashboardTodo = this.localStorage.getDataObject(DBkeys.SHOW_DASHBOARD_TODO);
        if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_BANNER))
            this._showDashboardBanner = this.localStorage.getDataObject(DBkeys.SHOW_DASHBOARD_BANNER);
    };
    ConfigurationService.prototype.saveToLocalStore = function (data, key) {
        var _this = this;
        setTimeout(function () { return _this.localStorage.savePermanentData(data, key); });
    };
    ConfigurationService.prototype.import = function (jsonValue) {
        this.clearLocalChanges();
        if (!jsonValue)
            return;
        var importValue = Utilities.JSonTryParse(jsonValue);
        if (importValue.language != null)
            this.language = importValue.language;
        if (importValue.homeUrl != null)
            this.homeUrl = importValue.homeUrl;
        if (importValue.theme != null)
            this.theme = importValue.theme;
        if (importValue.showDashboardStatistics != null)
            this.showDashboardStatistics = importValue.showDashboardStatistics;
        if (importValue.showDashboardNotifications != null)
            this.showDashboardNotifications = importValue.showDashboardNotifications;
        if (importValue.showDashboardTodo != null)
            this.showDashboardTodo = importValue.showDashboardTodo;
        if (importValue.showDashboardBanner != null)
            this.showDashboardBanner = importValue.showDashboardBanner;
    };
    ConfigurationService.prototype.export = function (changesOnly) {
        if (changesOnly === void 0) { changesOnly = true; }
        var exportValue = {
            language: changesOnly ? this._language : this.language,
            homeUrl: changesOnly ? this._homeUrl : this.homeUrl,
            theme: changesOnly ? this._theme : this.theme,
            showDashboardStatistics: changesOnly ? this._showDashboardStatistics : this.showDashboardStatistics,
            showDashboardNotifications: changesOnly ? this._showDashboardNotifications : this.showDashboardNotifications,
            showDashboardTodo: changesOnly ? this._showDashboardTodo : this.showDashboardTodo,
            showDashboardBanner: changesOnly ? this._showDashboardBanner : this.showDashboardBanner
        };
        return JSON.stringify(exportValue);
    };
    ConfigurationService.prototype.clearLocalChanges = function () {
        this._language = null;
        this._homeUrl = null;
        this._theme = null;
        this._showDashboardStatistics = null;
        this._showDashboardNotifications = null;
        this._showDashboardTodo = null;
        this._showDashboardBanner = null;
        this.localStorage.deleteData(DBkeys.LANGUAGE);
        this.localStorage.deleteData(DBkeys.HOME_URL);
        this.localStorage.deleteData(DBkeys.THEME);
        this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_STATISTICS);
        this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
        this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_TODO);
        this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_BANNER);
        this.resetLanguage();
    };
    ConfigurationService.prototype.resetLanguage = function () {
        // let language = this.translationService.useBrowserLanguage();
        // if (language) {
        //     this._language = language;
        // }
        // else {
        //     this._language = this.translationService.changeLanguage()
        // }
    };
    Object.defineProperty(ConfigurationService.prototype, "language", {
        get: function () {
            if (this._language != null)
                return this._language;
            return ConfigurationService.defaultLanguage;
        },
        set: function (value) {
            this._language = value;
            this.saveToLocalStore(value, DBkeys.LANGUAGE);
            // this.translationService.changeLanguage(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "homeUrl", {
        get: function () {
            if (this._homeUrl != null)
                return this._homeUrl;
            return ConfigurationService.defaultHomeUrl;
        },
        set: function (value) {
            this._homeUrl = value;
            this.saveToLocalStore(value, DBkeys.HOME_URL);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "theme", {
        get: function () {
            if (this._theme != null)
                return this._theme;
            return ConfigurationService.defaultTheme;
        },
        set: function (value) {
            this._theme = value;
            this.saveToLocalStore(value, DBkeys.THEME);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "showDashboardStatistics", {
        get: function () {
            if (this._showDashboardStatistics != null)
                return this._showDashboardStatistics;
            return ConfigurationService.defaultShowDashboardStatistics;
        },
        set: function (value) {
            this._showDashboardStatistics = value;
            this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_STATISTICS);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "showDashboardNotifications", {
        get: function () {
            if (this._showDashboardNotifications != null)
                return this._showDashboardNotifications;
            return ConfigurationService.defaultShowDashboardNotifications;
        },
        set: function (value) {
            this._showDashboardNotifications = value;
            this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "showDashboardTodo", {
        get: function () {
            if (this._showDashboardTodo != null)
                return this._showDashboardTodo;
            return ConfigurationService.defaultShowDashboardTodo;
        },
        set: function (value) {
            this._showDashboardTodo = value;
            this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_TODO);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "showDashboardBanner", {
        get: function () {
            if (this._showDashboardBanner != null)
                return this._showDashboardBanner;
            return ConfigurationService.defaultShowDashboardBanner;
        },
        set: function (value) {
            this._showDashboardBanner = value;
            this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_BANNER);
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationService.appVersion = "2.6.1";
    //***Specify default configurations here***
    ConfigurationService.defaultLanguage = "pt-br";
    ConfigurationService.defaultHomeUrl = "/";
    ConfigurationService.defaultTheme = "Default";
    ConfigurationService.defaultShowDashboardStatistics = true;
    ConfigurationService.defaultShowDashboardNotifications = true;
    ConfigurationService.defaultShowDashboardTodo = false;
    ConfigurationService.defaultShowDashboardBanner = true;
    return ConfigurationService;
}());
export { ConfigurationService };
