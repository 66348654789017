import { Injectable } from "@angular/core";
import { AmazonDTO } from "../models/amazon/amazon-retorno.model";
import { UserPerfil } from "../models/user.perfil";
import { AccountService } from "../services/account.service";
import { AuthServices } from "../services/auth.service";
import { DBkeys } from "../services/db-Keys";
import { Utilities } from '../services/utilities';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()

export class GlobalUtilitiesProvider {
    public _displayButton = 'none';
    public _logado: boolean = false;
    public _usuario = new UserPerfil();
    public _idFacebookLogado = null;
    public _fotoAntiga = null;
    public _emailtrocado = false;
    public _esqueciSenha = false;
    public _trocouFoto = false;
    public _listaEmpresaProvisoria: AmazonDTO[] = [];
    public _jaPassouPeloResolver: boolean = false;
    usuario: any;

    constructor(private apiUsuario: AccountService, private authService: AuthServices) {

    }

    public SaveUser() {
        this.apiUsuario.getUserViewModelPromise(this.GetIdUser).then(results => {
            this._usuario = results;
            if (this._usuario.Foto == '/Resources/img/png/no_picture.jpg' || this._usuario.Foto == null) {
                this._usuario.Foto = '../../assets/theme/img/no_picture.jpg';
            }
            const resultJSON = JSON.stringify(results);
            this.SaveToLocalStore({ data: resultJSON, key: DBkeys.USUARIO_LOGADO });
        },
            _error => {
                Utilities.SwalWarning('Ops', 'Erro ao salvar o usuário na chave');
            });
    }


    public SaveUserAsync(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiUsuario.getUserViewModelPromise(this.GetIdUser).then(results => {
                this._usuario = results;
                if (this._usuario) {
                    if (this._usuario.Foto == '/Resources/img/png/no_picture.jpg' || this._usuario.Foto == null) {
                        this._usuario.Foto = '../../assets/theme/img/no_picture.jpg';
                    }
                }
                const resultJSON = JSON.stringify(results);
                this.SaveToLocalStore({ data: resultJSON, key: DBkeys.USUARIO_LOGADO });
                resolve(true);
            }, _error => {
                reject(false);
            });
        });
    }


    public ReturnUserObservable<UserPerfil>(): Observable<UserPerfil> {
        this._usuario = JSON.parse(localStorage.getItem(DBkeys.USUARIO_LOGADO));
        let usuarioTemp = JSON.parse(localStorage.getItem(DBkeys.USUARIO_LOGADO));
        return usuarioTemp;
    }

    public ReturnUser(): UserPerfil {
        this._usuario = JSON.parse(localStorage.getItem(DBkeys.USUARIO_LOGADO));
        let usuarioTemp = JSON.parse(localStorage.getItem(DBkeys.USUARIO_LOGADO));
        return usuarioTemp;
    }


    private SaveToLocalStore({ data, key }: { data: any; key: string; }) {
        return new Promise((resolve) => {
            localStorage.setItem(key, data);
            resolve();
        });
    }


    get GetIdUser(): string {
        return this.authService.currentUser ? this.authService.currentUser.id : "";
    }


    public SaveListBusinessProvisional(listaEmpresa?: AmazonDTO[]) {
        const resultJSON = JSON.stringify(listaEmpresa);
        localStorage.setItem(DBkeys.LISTA_EMPRESA_PROVISORIA, resultJSON);
    }


    public SaveLastRoute(pagina?: string) {
        localStorage.setItem(DBkeys.ULTIMA_PAGINA, pagina);
    }


    public ReturnLastRoute() {
        let retorno = localStorage.getItem(DBkeys.ULTIMA_PAGINA);
        return '/' + (retorno !== null ? retorno : 'minha-conta');
    }


    public RemoveAcents(text: string) {
        text = text.toLowerCase();
        text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
        text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
        text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
        text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
        text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
        text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
        return text;
    }
}
