var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorHandler } from "@angular/core";
var AppErrorHandler = /** @class */ (function (_super) {
    __extends(AppErrorHandler, _super);
    //private alertService: AlertService;
    function AppErrorHandler() {
        return _super.call(this) || this;
    }
    AppErrorHandler.prototype.handleError = function (error) {
        //if (this.alertService == null) {
        //    this.alertService = this.injector.get(AlertService);
        //}
        //this.alertService.showStickyMessage("Fatal Error!", "An unresolved error has occured. Please reload the page to correct this error", MessageSeverity.warn);
        //this.alertService.showStickyMessage("Unhandled Error", error.message || error, MessageSeverity.error, error);
        if (confirm("Ops!\nAlgo Aconeceu!?\n\n Problema: " + error.message))
            window.location.reload(true);
        _super.prototype.handleError.call(this, error);
    };
    return AppErrorHandler;
}(ErrorHandler));
export { AppErrorHandler };
