<div class="container">
    <header class="pageHeader">
        <h3><i class="fa fa-exclamation-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.NotFound'  }}</h3>
    </header>
    NÃO DEU
    <div [@fadeInOut] class="vertical-center-flex">
        <div class="center-block">
            <div class="icon-container"><i class='fa fa-exclamation-circle'></i> {{'notFound.404'  }}</div>
            <div class="text-muted error-description">{{'notFound.pageNotFound'  }}</div>
            <div><a class="btn btn-primary" routerLink="/"><i class='fa fa-home'></i> {{'notFound.backToHome'  }}</a></div>
        </div>
    </div>
</div>
