
import { NgModule } from '@angular/core';

import { TakeFirstName } from './take-first-name.pipe';
import { TiraAcentoPipe } from './tira-acento.pipe';
import { FilterByName } from './filter-by-name.pipe';
import { FilterByNameBusiness } from './filter-by-name-business';
import { GroupByPipe } from './group-by.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        FilterByName,
        FilterByNameBusiness,
        GroupByPipe,
        TakeFirstName,
        TiraAcentoPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        FilterByName,
        FilterByNameBusiness,
        GroupByPipe,
        TakeFirstName,
        TiraAcentoPipe
    ]
})
export class PipeModule {
}