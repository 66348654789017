import { UserPerfil } from "../models/user.perfil";
import { AccountService } from "../services/account.service";
import { AuthServices } from "../services/auth.service";
import { DBkeys } from "../services/db-Keys";
import { Utilities } from '../services/utilities';
var GlobalUtilitiesProvider = /** @class */ (function () {
    function GlobalUtilitiesProvider(apiUsuario, authService) {
        this.apiUsuario = apiUsuario;
        this.authService = authService;
        this._displayButton = 'none';
        this._logado = false;
        this._usuario = new UserPerfil();
        this._idFacebookLogado = null;
        this._fotoAntiga = null;
        this._emailtrocado = false;
        this._esqueciSenha = false;
        this._trocouFoto = false;
        this._listaEmpresaProvisoria = [];
        this._jaPassouPeloResolver = false;
    }
    GlobalUtilitiesProvider.prototype.SaveUser = function () {
        var _this = this;
        this.apiUsuario.getUserViewModelPromise(this.GetIdUser).then(function (results) {
            _this._usuario = results;
            if (_this._usuario.Foto == '/Resources/img/png/no_picture.jpg' || _this._usuario.Foto == null) {
                _this._usuario.Foto = '../../assets/theme/img/no_picture.jpg';
            }
            var resultJSON = JSON.stringify(results);
            _this.SaveToLocalStore({ data: resultJSON, key: DBkeys.USUARIO_LOGADO });
        }, function (_error) {
            Utilities.SwalWarning('Ops', 'Erro ao salvar o usuário na chave');
        });
    };
    GlobalUtilitiesProvider.prototype.SaveUserAsync = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.apiUsuario.getUserViewModelPromise(_this.GetIdUser).then(function (results) {
                _this._usuario = results;
                if (_this._usuario) {
                    if (_this._usuario.Foto == '/Resources/img/png/no_picture.jpg' || _this._usuario.Foto == null) {
                        _this._usuario.Foto = '../../assets/theme/img/no_picture.jpg';
                    }
                }
                var resultJSON = JSON.stringify(results);
                _this.SaveToLocalStore({ data: resultJSON, key: DBkeys.USUARIO_LOGADO });
                resolve(true);
            }, function (_error) {
                reject(false);
            });
        });
    };
    GlobalUtilitiesProvider.prototype.ReturnUserObservable = function () {
        this._usuario = JSON.parse(localStorage.getItem(DBkeys.USUARIO_LOGADO));
        var usuarioTemp = JSON.parse(localStorage.getItem(DBkeys.USUARIO_LOGADO));
        return usuarioTemp;
    };
    GlobalUtilitiesProvider.prototype.ReturnUser = function () {
        this._usuario = JSON.parse(localStorage.getItem(DBkeys.USUARIO_LOGADO));
        var usuarioTemp = JSON.parse(localStorage.getItem(DBkeys.USUARIO_LOGADO));
        return usuarioTemp;
    };
    GlobalUtilitiesProvider.prototype.SaveToLocalStore = function (_a) {
        var data = _a.data, key = _a.key;
        return new Promise(function (resolve) {
            localStorage.setItem(key, data);
            resolve();
        });
    };
    Object.defineProperty(GlobalUtilitiesProvider.prototype, "GetIdUser", {
        get: function () {
            return this.authService.currentUser ? this.authService.currentUser.id : "";
        },
        enumerable: true,
        configurable: true
    });
    GlobalUtilitiesProvider.prototype.SaveListBusinessProvisional = function (listaEmpresa) {
        var resultJSON = JSON.stringify(listaEmpresa);
        localStorage.setItem(DBkeys.LISTA_EMPRESA_PROVISORIA, resultJSON);
    };
    GlobalUtilitiesProvider.prototype.SaveLastRoute = function (pagina) {
        localStorage.setItem(DBkeys.ULTIMA_PAGINA, pagina);
    };
    GlobalUtilitiesProvider.prototype.ReturnLastRoute = function () {
        var retorno = localStorage.getItem(DBkeys.ULTIMA_PAGINA);
        return '/' + (retorno !== null ? retorno : 'minha-conta');
    };
    GlobalUtilitiesProvider.prototype.RemoveAcents = function (text) {
        text = text.toLowerCase();
        text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
        text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
        text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
        text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
        text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
        text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
        return text;
    };
    return GlobalUtilitiesProvider;
}());
export { GlobalUtilitiesProvider };
