import { APP_BASE_HREF } from '@angular/common';
import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { ToastaModule } from 'ngx-toasta';
import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider } from "angular-6-social-login";
import { OwlDateTimeIntl } from 'ng-pick-datetime';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { BootstrapDatepickerDirective } from './directives/bootstrap-datepicker.directive';
import { AppTitleService } from './services/app-title.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { EndpointFactory } from './services/endpoint-factory.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';
import { UploadFileService } from './providers/form-upload/upload-file.service';
import { PreviousRouteService } from './providers/previous-route-service';
import { GlobalUtilitiesProvider } from './providers/global-utilities.provider';
import { AppComponent } from "./components/app.component";
import { NotFoundComponent } from "./components/sistema/not-found/not-found.component";
import { PipeModule } from './pipes/pipe.module';

export class DefaultIntl extends OwlDateTimeIntl {
  upSecondLabel = 'Add a second';
  downSecondLabel = 'Minus a second';
  upMinuteLabel = 'Add a minute';
  downMinuteLabel = 'Minus a minute';
  upHourLabel = 'Add a hour';
  downHourLabel = 'Minus a hour';
  prevMonthLabel = 'Previous month';
  nextMonthLabel = 'Next month';
  prevYearLabel = 'Previous year';
  nextYearLabel = 'Next year';
  prevMultiYearLabel = 'Previous 21 years';
  nextMultiYearLabel = 'Next 21 years';
  switchToMonthViewLabel = 'Change to month view';
  switchToMultiYearViewLabel = 'Choose month and year';
  cancelBtnLabel = 'Cancelar';
  setBtnLabel = 'Escolher';
  rangeFromLabel = 'From';
  rangeToLabel = 'To';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastaModule,
    SweetAlert2Module.forRoot({
      showLoaderOnConfirm: true,
      customClass: 'bounceIn',
      confirmButtonClass: 'botaoAlertSweet',
      cancelButtonClass: 'botaoAlertSweet',
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: 'botaoAlertSweetTittle',
      text: 'botaoAlertSweet'
    }),
    SocialLoginModule,
    PipeModule
  ],
  exports: [RouterModule],
  declarations: [
    AppComponent,
    NotFoundComponent,
    EqualValidator,
    LastElementDirective,
    AutofocusDirective,
    BootstrapDatepickerDirective    
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'br' },
    // { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    GlobalUtilitiesProvider,
    AccountEndpoint,
    LocalStoreManager,
    EndpointFactory,
    AlertService,
    AppTitleService,
    AccountService,
    UploadFileService,
    PreviousRouteService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    ConfigurationService,
    NotificationService,
    NotificationEndpoint,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("655663573952-ddbtikcp34a1ahtsvcd0hvp6gfbdsdup.apps.googleusercontent.com")
      },

    ]
  );
  return config;
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
