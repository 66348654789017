/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./not-found.component";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: { "animation": [{ type: 7, name: "fadeInOut", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "0.4s ease-in" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.4s 10ms ease-out" }], options: null }], options: {} }] } });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "header", [["class", "pageHeader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-circle fa-lg page-caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""])), (_l()(), i1.ɵted(-1, null, [" N\u00C3O DEU "])), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "vertical-center-flex"]], [[24, "@fadeInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "center-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-exclamation-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "text-muted error-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["class", "btn btn-primary"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-home"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, [" ", ""]))], function (_ck, _v) { var currVal_6 = "/"; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = "pageHeader.NotFound"; _ck(_v, 4, 0, currVal_0); var currVal_1 = undefined; _ck(_v, 6, 0, currVal_1); var currVal_2 = "notFound.404"; _ck(_v, 10, 0, currVal_2); var currVal_3 = "notFound.pageNotFound"; _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 15).target; var currVal_5 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_7 = "notFound.backToHome"; _ck(_v, 17, 0, currVal_7); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i4.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i1.ɵccf("not-found", i4.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
