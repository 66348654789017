
import { Injectable, Injector } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AccountEndpoint } from './account-endpoint.service';
import { AuthServices } from './auth.service';
import { User } from '../models/user.model';
import { Role } from '../models/role.model';
import { Permission, PermissionNames, PermissionValues } from '../models/permission.model';
import { UserEdit } from '../models/user-edit.model';
import { UserPerfil } from "../models/user.perfil";
import { UsuarioCompleto } from '../models/usuario/usuario-completo.model';
import { ConfigurationService } from './configuration.service';
import { EndpointFactory } from './endpoint-factory.service';

export type RolesChangedOperation = "add" | "delete" | "modify";
export type RolesChangedEventArg = { roles: Role[] | string[], operation: RolesChangedOperation };

@Injectable()
export class AccountService extends EndpointFactory {

  public static readonly roleAddedOperation: RolesChangedOperation = "add";
  public static readonly roleDeletedOperation: RolesChangedOperation = "delete";
  public static readonly roleModifiedOperation: RolesChangedOperation = "modify";

  private _rolesChanged = new Subject<RolesChangedEventArg>();
  private readonly UrlProducao: string = "https://apiminhaconta.listamais.com.br";
  private readonly UrlDesenvolvimento: string = "http://localhost:54392";
  private authServiceS: AuthServices;
  get usersUrl() { return this.configurations.baseUrl; }

  constructor(router: Router, http: HttpClient, authService: AuthServices,
    public accountEndpoint: AccountEndpoint, public configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
    this.authServiceS = authService;
  }

  async getUserViewModelPromise(userId?: string): Promise<UserPerfil> {
    return new Promise((resolve, reject) => {
      this.accountEndpoint.getUserEndpointPromise(userId).then(valor => {
        resolve(valor);
      }).catch((error) => {
        reject();
      });
    });
  }

  getUserViewModel(userId?: string) {
    return this.accountEndpoint.getUserEndpoint<UserPerfil>(userId);
  }

  getUser(userId?: string) {
    return this.accountEndpoint.getUserEndpoint<User>(userId);
  }

  getUserByFacebook(facebookId?: string, email?: string, username?: string) {
    return fetch(`${this.usersUrl}/api/account/users/facebook/verifica/${facebookId}/${email}/${username}`, { method: 'get' })
      .then(response => {
        if (!response.ok) {
          return null;
        }
        return response;
      }).catch(error => {
        console.log(error);
        return null;
      });
  }

  getUserByGoogle(googleId?: string) {
    return fetch(`${this.usersUrl}/api/account/users/google/verifica/${googleId}`, { method: 'get' })
      .then(response => {
        if (!response.ok) {
          console.log(response);
        }
        return response;
      }).catch(error => {
        console.log(error);
        return null;
      });
  }



  createNewUserFacebook(user: UsuarioCompleto) {
    return fetch(`${this.usersUrl}/api/account/users/facebook/novo/`, {
      method: 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(user)
    });
  }

  createNewUserGoogle(user: UsuarioCompleto) {
    return fetch(`${this.usersUrl}/api/account/users/google/novo/`, {
      method: 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(user)
    });
  }

  reenviarEmailConfirmacao(idUsuario: string) {
    return fetch(`${this.usersUrl}/api/account/users/listamais/reenviarEmail/`, {
      method: 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(idUsuario)
    });
  }

  createNewUserListaMais(user: UsuarioCompleto) {
    return fetch(`${this.usersUrl}/api/account/users/listamais/novo/`, {
      method: 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(user)
    });
  }


  getUserAndRoles(userId?: string) {

    return forkJoin(
      this.accountEndpoint.getUserEndpoint<User>(userId),
      this.accountEndpoint.getRolesEndpoint<Role[]>());
  }

  getUsers(page?: number, pageSize?: number) {

    return this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize);
  }

  getUsersAndRoles(page?: number, pageSize?: number) {
    return forkJoin(
      this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize),
      this.accountEndpoint.getRolesEndpoint<Role[]>());
  }

  deleteUserLogico(userId?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.accountEndpoint.deletaUsuarioLogico(userId).subscribe(resposta => {
        resolve(true);
      }, error => {
        reject(false);
      });
    });
  }

  deleteUserLogicoEmail(email?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.accountEndpoint.deletaUsuarioLogicoEmail(email).subscribe(resposta => {
        resolve(true);
      }, error => {
        reject(false);
      });
    });
  }

  updateUser(user: UserEdit) {
    if (user.id) {
      return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
    } else {
      return this.accountEndpoint.getUserByUserNameEndpoint<User>(user.userName).pipe<User>(
        mergeMap(foundUser => {
          user.id = foundUser.id;
          return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
        }));
    }
  }

  updateUserCompleto(user: UsuarioCompleto) {
    if (user.Id) {
      return this.accountEndpoint.UpdateUsuarioCompletoEndpoint(user, user.Id);
    }
  }

  uploadFotoPerfil(userId: string, file: File) {

    return this.accountEndpoint.uploadFotoPerfil(userId, file);
  }


  newUser(user: UserEdit) {
    return this.accountEndpoint.getNewUserEndpoint<User>(user);
  }


  getUserPreferences() {
    return this.accountEndpoint.getUserPreferencesEndpoint<string>();
  }

  updateUserPreferences(configuration: string) {
    return this.accountEndpoint.getUpdateUserPreferencesEndpoint(configuration);
  }


  deleteUser(userOrUserId: string | UserEdit): Observable<User> {

    if (typeof userOrUserId === 'string' || userOrUserId instanceof String) {
      return this.accountEndpoint.getDeleteUserEndpoint<User>(<string>userOrUserId).pipe<User>(
        tap(data => this.onRolesUserCountChanged(data.roles)));
    }
    else {

      if (userOrUserId.id) {
        return this.deleteUser(userOrUserId.id);
      }
      else {
        return this.accountEndpoint.getUserByUserNameEndpoint<User>(userOrUserId.userName).pipe<User>(
          mergeMap(user => this.deleteUser(user.id)));
      }
    }
  }


  unblockUser(userId: string) {
    return this.accountEndpoint.getUnblockUserEndpoint(userId);
  }


  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p == permissionValue);
  }


  refreshLoggedInUser() {
    return this.authServiceS.refreshLogin();
  }




  getRoles(page?: number, pageSize?: number) {

    return this.accountEndpoint.getRolesEndpoint<Role[]>(page, pageSize);
  }


  getRolesAndPermissions(page?: number, pageSize?: number) {

    return forkJoin(
      this.accountEndpoint.getRolesEndpoint<Role[]>(page, pageSize),
      this.accountEndpoint.getPermissionsEndpoint<Permission[]>());
  }


  updateRole(role: Role) {
    if (role.id) {
      return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id).pipe(
        tap(data => this.onRolesChanged([role], AccountService.roleModifiedOperation)));
    }
    else {
      return this.accountEndpoint.getRoleByRoleNameEndpoint<Role>(role.name).pipe<Role>(
        mergeMap(foundRole => {
          role.id = foundRole.id;
          return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id);
        }),
        tap(data => this.onRolesChanged([role], AccountService.roleModifiedOperation)));
    }
  }


  newRole(role: Role) {
    return this.accountEndpoint.getNewRoleEndpoint<Role>(role).pipe<Role>(
      tap(data => this.onRolesChanged([role], AccountService.roleAddedOperation)));
  }


  deleteRole(roleOrRoleId: string | Role): Observable<Role> {

    if (typeof roleOrRoleId === 'string' || roleOrRoleId instanceof String) {
      return this.accountEndpoint.getDeleteRoleEndpoint<Role>(<string>roleOrRoleId).pipe<Role>(
        tap(data => this.onRolesChanged([data], AccountService.roleDeletedOperation)));
    }
    else {

      if (roleOrRoleId.id) {
        return this.deleteRole(roleOrRoleId.id);
      }
      else {
        return this.accountEndpoint.getRoleByRoleNameEndpoint<Role>(roleOrRoleId.name).pipe<Role>(
          mergeMap(role => this.deleteRole(role.id)));
      }
    }
  }

  getPermissions() {

    return this.accountEndpoint.getPermissionsEndpoint<Permission[]>();
  }


  private onRolesChanged(roles: Role[] | string[], op: RolesChangedOperation) {
    this._rolesChanged.next({ roles: roles, operation: op });
  }


  onRolesUserCountChanged(roles: Role[] | string[]) {
    return this.onRolesChanged(roles, AccountService.roleModifiedOperation);
  }


  getRolesChangedEvent(): Observable<RolesChangedEventArg> {
    return this._rolesChanged.asObservable();
  }



  get permissions(): PermissionValues[] {
    return this.authServiceS.userPermissions;
  }

  get currentUser() {
    return this.authServiceS.currentUser;
  }
}
