import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, AlertDialog, DialogType, AlertMessage, MessageSeverity } from '../services/alert.service';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { AppTitleService } from '../services/app-title.service';
import { AuthServices } from '../services/auth.service';
import { UserPerfil } from "../models/user.perfil";
import { GlobalUtilitiesProvider } from "../providers/global-utilities.provider";
import { Component, ViewEncapsulation, OnInit, ViewChildren, AfterViewInit, QueryList, OnDestroy } from "@angular/core";

import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';
import { Router, NavigationStart } from '@angular/router';
import { UsuarioEmpresa } from '../models/empresa/usuario-empresa.model';
import { Empresa } from '../models/empresa/empresa.model';
import { Endereco } from '../models/endereco/endereco.model';
import { Utilities } from '../services/utilities';
import { SweetAlertResult } from 'sweetalert2';
import { Subject, BehaviorSubject } from 'rxjs';

let alertify: any = require('../assets/scripts/alertify.js');
@Component({
  selector: "app-root",
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  preserveWhitespaces: false
})
export class AppComponent implements OnInit, OnDestroy {

  //#region Variaveis
  public usuario = new UserPerfil();
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  appTitle = "ListaMaisApp";
  appLogo = require("../assets/images/logo-white.png");

  stickyToasties: number[] = [];
  dataLoadingConsecutiveFailurs = 0;
  notificationsLoadingSubscription: any;

  @ViewChildren('loginModal,loginControl')
  modalLoginControls: QueryList<any>;
  loginModal: ModalDirective;
  display = 'none';
  _imageLogo = '/assets/theme/img/logo-listamais-y.svg';
  _styleLogo = '80%';
  _styleContainerLogo = '62%';
  _styleLogoMobile = '70%';

  //#endregion

  constructor(storageManager: LocalStoreManager,
    private toastaService: ToastaService,
    private alertService: AlertService,
    private appTitleService: AppTitleService,
    private authService: AuthServices,
    public router: Router,
    public variaveisGlobais: GlobalUtilitiesProvider) {
    storageManager.initialiseStorageSyncListener();

    this.appTitleService.appName = this.appTitle;
  }

  onLoadAccount() {
    this.variaveisGlobais._displayButton = 'block';
    this.variaveisGlobais._logado = true;
    this.usuario = this.variaveisGlobais.ReturnUser();
  }


  private stopLoading() {
    this.alertService.stopLoadingMessage();
  }


  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;
    this.isAppLoaded = true;
    this.removePrebootScreen = true;
    setTimeout(() => this.isAppLoaded = true, 500);
    setTimeout(() => this.removePrebootScreen = true, 700);

    if (this.isUserLoggedIn) {
      this.onLoadAccount();
      this.alertService.resetStickyMessage();
      if (!this.authService.isSessionExpired) {
        this.usuario = this.variaveisGlobais.ReturnUser();
      }
    }


    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.onLoadAccount();
        this.alertService.resetStickyMessage();
        if (!this.authService.isSessionExpired) {
          this.usuario = this.variaveisGlobais.ReturnUser();
        }
      }
    }, 500);


    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
    this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));
    this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;
    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let url = (<NavigationStart>event).url;
        if (url !== url.toLowerCase()) {
          this.router.navigate([(<NavigationStart>event).url.toLowerCase()]);
        }
      }
    });
  }

  public PintaBloco() {
    document.getElementById("empresa-botao").className = "active";
  }


  ngOnDestroy() {
    localStorage.clear();
  }

  public Open() {
    this.display = 'block';
  }

  public Close() {
    this.display = 'none';
  }

  showDialog(dialog: AlertDialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || "OK",
        cancel: dialog.cancelLabel || "Cancelar"
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);
        break;

      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e: any) => {
            if (e) {
              dialog.okCallback();
            } else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          });
        break;

      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e: any, val: any) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          }, dialog.defaultValue);

        break;
    }
  }

  showToast(message: AlertMessage, isSticky: boolean) {

    if (message == null) {
      for (let id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    let toastOptions: ToastOptions = {
      title: message.summary,
      msg: message.detail,
      timeout: isSticky ? 0 : 4000
    };


    if (isSticky) {
      toastOptions.onAdd = (toast: ToastData) => this.stickyToasties.push(toast.id);

      toastOptions.onRemove = (toast: ToastData) => {
        let index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }
        toast.onAdd = null;
        toast.onRemove = null;
      };
    }

    switch (message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break;
      case MessageSeverity.info: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break;
      case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }

  public voltarAoSite() {
    this.verificaEmpresaEdicao('https://www.listamais.com.br', false);
  }

  public IrParaHome() {
    this.router.navigate(['/home']);
  }

  private verificaEmpresaEdicao(site: string, tipo: boolean): void {
    let usuEmp: UsuarioEmpresa = JSON.parse(localStorage.getItem('empresaEdicao'));
    if (usuEmp) {
      Utilities.SwalWarningChoises('Atenção!', 'Tinha uma empresa em edição, deseja terminar o cadastro antes de sair?', 'Sim!', 'Não, pode apagar!').then((result: SweetAlertResult) => {
        if (result.value) {
          this.router.navigate(['/empresa/cadastro']);
        } else {
          localStorage.removeItem('empresaEdicao');
          if (tipo) {

            // this.variaveisGlobais._logado = false;
            // this.variaveisGlobais._displayButton = 'none';
            // localStorage.removeItem('USUARIOLOGADO');
            // this.authService.logout();
            // localStorage.clear();

            window.location.href = site;
          } else {
            setTimeout(() => {
              window.location.replace(site);
            }, 100);
          }
        }
      });
    } else {

      // this.variaveisGlobais._logado = false;
      // this.variaveisGlobais._displayButton = 'none';
      // localStorage.removeItem('USUARIOLOGADO');
      // this.authService.logout();

      localStorage.removeItem('empresaEdicao');
      
      setTimeout(() => {
        window.location.replace(site);
      }, 100);
    }
  }

  public logoutTrocaEmail() {
    this.variaveisGlobais._logado = false;
    this.variaveisGlobais._displayButton = 'none';
    localStorage.clear();
    localStorage.removeItem('USUARIOLOGADO');
    this.authService.logout();
  }

  public logout() {
    this.variaveisGlobais._logado = false;
    this.variaveisGlobais._displayButton = 'none';
    localStorage.clear();
    localStorage.removeItem('USUARIOLOGADO');
    this.authService.logout();
    setTimeout(() => {
      window.location.replace("https://www.listamais.com.br");
    }, 100);
  }

  public logoutMinhaConta() {
    this.variaveisGlobais._logado = false;
    this.variaveisGlobais._displayButton = 'none';
    localStorage.clear();
    localStorage.removeItem('USUARIOLOGADO');
    this.authService.logout();
  }

  public logoutSiteIntegrado() {
    this.verificaEmpresaEdicao('https://www.listamais.com.br/MinhaConta/SairMinhaConta', true);
  }

  getYear() {
    return new Date().getUTCFullYear();
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : "";
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }

  GravarRotaMinhaConta() {
    this.variaveisGlobais.SaveLastRoute('minha-conta');
  }

}
