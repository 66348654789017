import { Telefone } from "./telefone/telefone.model";
export class UserPerfil {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    constructor(id?: string, nomeUsuario?: string, nomeCompleto?: string, email?: string, emailNovo?: string, emailConfirmed?: boolean, cargo?: string, sobrenome?: string, dtNascimento?: Date, dtUltimoAcesso?: Date, sexo?: string, nome?: string, ativo?: boolean, usuarioDeletado?: boolean, senha?: string, bloqueado?: boolean, telefones?: Telefone[], foto?: string, twoFactorEnabled?: boolean) {
        this.Id = id;
        this.NomeUsuario = nomeUsuario;
        this.NomeCompleto = nomeCompleto;
        this.Email = email;
        this.EmailNovo = emailNovo;
        this.EmailConfirmed = emailConfirmed;
        this.Cargo = cargo;
        this.Nome = nome;
        this.Sobrenome = sobrenome;
        this.DtNascimento = dtNascimento;
        this.DtUltimoAcesso = dtUltimoAcesso;
        this.Sexo = sexo;
        this.Foto = foto;
        this.Ativo = ativo;
        this.Senha = senha;
        this.UsuarioDeletado = usuarioDeletado;
        this.Bloqueado = bloqueado;
        this.Telefones = telefones;
        this.TwoFactorEnabled = twoFactorEnabled;
    }

    get NomeAmigavel(): string {
        let name = this.NomeCompleto || this.NomeUsuario;
        if (this.Cargo)
            name = this.Cargo + " " + name;
        return name;
    }
    
    public Id: string;
    public NomeUsuario?: string = "";
    public NomeCompleto?: string = "";
    public Email: string;
    public EmailNovo?: string = "";
    public EmailConfirmed?: boolean = false;
    public Cargo?: string;
    public Nome?: string = "";
    public Sobrenome?: string = "";
    public DtNascimento?: Date;
    public DtUltimoAcesso?: Date;
    public Sexo?: string = null;
    public Foto?: string = "../../assets/theme/img/no_picture.jpg";
    public Ativo: boolean;
    public UsuarioDeletado: boolean = false;
    public Complemento?: string = "";
    public Bloqueado: boolean = false;
    public Senha?: string = "";
    public Telefones: Telefone[];
    public TwoFactorEnabled?: boolean;
}
