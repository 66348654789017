var UserPerfil = /** @class */ (function () {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    function UserPerfil(id, nomeUsuario, nomeCompleto, email, emailNovo, emailConfirmed, cargo, sobrenome, dtNascimento, dtUltimoAcesso, sexo, nome, ativo, usuarioDeletado, senha, bloqueado, telefones, foto, twoFactorEnabled) {
        this.NomeUsuario = "";
        this.NomeCompleto = "";
        this.EmailNovo = "";
        this.EmailConfirmed = false;
        this.Nome = "";
        this.Sobrenome = "";
        this.Sexo = null;
        this.Foto = "../../assets/theme/img/no_picture.jpg";
        this.UsuarioDeletado = false;
        this.Complemento = "";
        this.Bloqueado = false;
        this.Senha = "";
        this.Id = id;
        this.NomeUsuario = nomeUsuario;
        this.NomeCompleto = nomeCompleto;
        this.Email = email;
        this.EmailNovo = emailNovo;
        this.EmailConfirmed = emailConfirmed;
        this.Cargo = cargo;
        this.Nome = nome;
        this.Sobrenome = sobrenome;
        this.DtNascimento = dtNascimento;
        this.DtUltimoAcesso = dtUltimoAcesso;
        this.Sexo = sexo;
        this.Foto = foto;
        this.Ativo = ativo;
        this.Senha = senha;
        this.UsuarioDeletado = usuarioDeletado;
        this.Bloqueado = bloqueado;
        this.Telefones = telefones;
        this.TwoFactorEnabled = twoFactorEnabled;
    }
    Object.defineProperty(UserPerfil.prototype, "NomeAmigavel", {
        get: function () {
            var name = this.NomeCompleto || this.NomeUsuario;
            if (this.Cargo)
                name = this.Cargo + " " + name;
            return name;
        },
        enumerable: true,
        configurable: true
    });
    return UserPerfil;
}());
export { UserPerfil };
