
export class User {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    constructor(id?: string, userName?: string, fullName?: string, email?: string, jobTitle?: string, phoneNumber?: string,configuration?: string, foto?: string, nome?: string,  roles?: string[], senha?: string) {

        this.id = id;
        this.userName = userName;
        this.fullName = fullName;
        this.email = email;
        this.jobTitle = jobTitle;
        this.phoneNumber = phoneNumber;
        this.configuration = configuration;
        this.Foto = foto;
        this.Nome = nome;
        this.roles = roles;
        this.senha = senha;
    }


    get friendlyName(): string {
        let name = this.fullName || this.userName;

        if (this.jobTitle)
            name = this.jobTitle + " " + name;

        return name;
    }


    public id: string;
    public userName: string;
    public fullName: string;
    public email: string;
    public jobTitle: string;
    public phoneNumber: string;
    public configuration: string;
    public Foto: string;
    public Nome: string;
    public senha?: string;
    // public sobrenome: string;
    // public dtNascimento: Date;
    // public sexo: string;
    // public siteBlog: string;
    // public facebook: string;
    // public google: string;
    // public twitter: string;
    // public linkedin: string;
    // public instagram: string;
    // public idFacebook: string;
    // public senha: string;
    // public dtCriacao: Date;
    // public dtUltimoAcesso: Date;
    // public codigoAtivacao: string;
    // public usuarioInterno: boolean;
    public isEnabled: boolean;
    public isLockedOut: boolean;
    public roles: string[];
}
