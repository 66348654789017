var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AccountEndpoint } from './account-endpoint.service';
import { AuthServices } from './auth.service';
import { Permission, PermissionValues } from '../models/permission.model';
import { ConfigurationService } from './configuration.service';
import { EndpointFactory } from './endpoint-factory.service';
var AccountService = /** @class */ (function (_super) {
    __extends(AccountService, _super);
    function AccountService(router, http, authService, accountEndpoint, configurations, injector) {
        var _this = _super.call(this, http, configurations, injector) || this;
        _this.accountEndpoint = accountEndpoint;
        _this.configurations = configurations;
        _this._rolesChanged = new Subject();
        _this.UrlProducao = "https://apiminhaconta.listamais.com.br";
        _this.UrlDesenvolvimento = "http://localhost:54392";
        _this.authServiceS = authService;
        return _this;
    }
    Object.defineProperty(AccountService.prototype, "usersUrl", {
        get: function () { return this.configurations.baseUrl; },
        enumerable: true,
        configurable: true
    });
    AccountService.prototype.getUserViewModelPromise = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.accountEndpoint.getUserEndpointPromise(userId).then(function (valor) {
                            resolve(valor);
                        }).catch(function (error) {
                            reject();
                        });
                    })];
            });
        });
    };
    AccountService.prototype.getUserViewModel = function (userId) {
        return this.accountEndpoint.getUserEndpoint(userId);
    };
    AccountService.prototype.getUser = function (userId) {
        return this.accountEndpoint.getUserEndpoint(userId);
    };
    AccountService.prototype.getUserByFacebook = function (facebookId, email, username) {
        return fetch(this.usersUrl + "/api/account/users/facebook/verifica/" + facebookId + "/" + email + "/" + username, { method: 'get' })
            .then(function (response) {
            if (!response.ok) {
                return null;
            }
            return response;
        }).catch(function (error) {
            console.log(error);
            return null;
        });
    };
    AccountService.prototype.getUserByGoogle = function (googleId) {
        return fetch(this.usersUrl + "/api/account/users/google/verifica/" + googleId, { method: 'get' })
            .then(function (response) {
            if (!response.ok) {
                console.log(response);
            }
            return response;
        }).catch(function (error) {
            console.log(error);
            return null;
        });
    };
    AccountService.prototype.createNewUserFacebook = function (user) {
        return fetch(this.usersUrl + "/api/account/users/facebook/novo/", {
            method: 'post',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(user)
        });
    };
    AccountService.prototype.createNewUserGoogle = function (user) {
        return fetch(this.usersUrl + "/api/account/users/google/novo/", {
            method: 'post',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(user)
        });
    };
    AccountService.prototype.reenviarEmailConfirmacao = function (idUsuario) {
        return fetch(this.usersUrl + "/api/account/users/listamais/reenviarEmail/", {
            method: 'post',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(idUsuario)
        });
    };
    AccountService.prototype.createNewUserListaMais = function (user) {
        return fetch(this.usersUrl + "/api/account/users/listamais/novo/", {
            method: 'post',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(user)
        });
    };
    AccountService.prototype.getUserAndRoles = function (userId) {
        return forkJoin(this.accountEndpoint.getUserEndpoint(userId), this.accountEndpoint.getRolesEndpoint());
    };
    AccountService.prototype.getUsers = function (page, pageSize) {
        return this.accountEndpoint.getUsersEndpoint(page, pageSize);
    };
    AccountService.prototype.getUsersAndRoles = function (page, pageSize) {
        return forkJoin(this.accountEndpoint.getUsersEndpoint(page, pageSize), this.accountEndpoint.getRolesEndpoint());
    };
    AccountService.prototype.deleteUserLogico = function (userId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.accountEndpoint.deletaUsuarioLogico(userId).subscribe(function (resposta) {
                resolve(true);
            }, function (error) {
                reject(false);
            });
        });
    };
    AccountService.prototype.deleteUserLogicoEmail = function (email) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.accountEndpoint.deletaUsuarioLogicoEmail(email).subscribe(function (resposta) {
                resolve(true);
            }, function (error) {
                reject(false);
            });
        });
    };
    AccountService.prototype.updateUser = function (user) {
        var _this = this;
        if (user.id) {
            return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
        }
        else {
            return this.accountEndpoint.getUserByUserNameEndpoint(user.userName).pipe(mergeMap(function (foundUser) {
                user.id = foundUser.id;
                return _this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
            }));
        }
    };
    AccountService.prototype.updateUserCompleto = function (user) {
        if (user.Id) {
            return this.accountEndpoint.UpdateUsuarioCompletoEndpoint(user, user.Id);
        }
    };
    AccountService.prototype.uploadFotoPerfil = function (userId, file) {
        return this.accountEndpoint.uploadFotoPerfil(userId, file);
    };
    AccountService.prototype.newUser = function (user) {
        return this.accountEndpoint.getNewUserEndpoint(user);
    };
    AccountService.prototype.getUserPreferences = function () {
        return this.accountEndpoint.getUserPreferencesEndpoint();
    };
    AccountService.prototype.updateUserPreferences = function (configuration) {
        return this.accountEndpoint.getUpdateUserPreferencesEndpoint(configuration);
    };
    AccountService.prototype.deleteUser = function (userOrUserId) {
        var _this = this;
        if (typeof userOrUserId === 'string' || userOrUserId instanceof String) {
            return this.accountEndpoint.getDeleteUserEndpoint(userOrUserId).pipe(tap(function (data) { return _this.onRolesUserCountChanged(data.roles); }));
        }
        else {
            if (userOrUserId.id) {
                return this.deleteUser(userOrUserId.id);
            }
            else {
                return this.accountEndpoint.getUserByUserNameEndpoint(userOrUserId.userName).pipe(mergeMap(function (user) { return _this.deleteUser(user.id); }));
            }
        }
    };
    AccountService.prototype.unblockUser = function (userId) {
        return this.accountEndpoint.getUnblockUserEndpoint(userId);
    };
    AccountService.prototype.userHasPermission = function (permissionValue) {
        return this.permissions.some(function (p) { return p == permissionValue; });
    };
    AccountService.prototype.refreshLoggedInUser = function () {
        return this.authServiceS.refreshLogin();
    };
    AccountService.prototype.getRoles = function (page, pageSize) {
        return this.accountEndpoint.getRolesEndpoint(page, pageSize);
    };
    AccountService.prototype.getRolesAndPermissions = function (page, pageSize) {
        return forkJoin(this.accountEndpoint.getRolesEndpoint(page, pageSize), this.accountEndpoint.getPermissionsEndpoint());
    };
    AccountService.prototype.updateRole = function (role) {
        var _this = this;
        if (role.id) {
            return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id).pipe(tap(function (data) { return _this.onRolesChanged([role], AccountService.roleModifiedOperation); }));
        }
        else {
            return this.accountEndpoint.getRoleByRoleNameEndpoint(role.name).pipe(mergeMap(function (foundRole) {
                role.id = foundRole.id;
                return _this.accountEndpoint.getUpdateRoleEndpoint(role, role.id);
            }), tap(function (data) { return _this.onRolesChanged([role], AccountService.roleModifiedOperation); }));
        }
    };
    AccountService.prototype.newRole = function (role) {
        var _this = this;
        return this.accountEndpoint.getNewRoleEndpoint(role).pipe(tap(function (data) { return _this.onRolesChanged([role], AccountService.roleAddedOperation); }));
    };
    AccountService.prototype.deleteRole = function (roleOrRoleId) {
        var _this = this;
        if (typeof roleOrRoleId === 'string' || roleOrRoleId instanceof String) {
            return this.accountEndpoint.getDeleteRoleEndpoint(roleOrRoleId).pipe(tap(function (data) { return _this.onRolesChanged([data], AccountService.roleDeletedOperation); }));
        }
        else {
            if (roleOrRoleId.id) {
                return this.deleteRole(roleOrRoleId.id);
            }
            else {
                return this.accountEndpoint.getRoleByRoleNameEndpoint(roleOrRoleId.name).pipe(mergeMap(function (role) { return _this.deleteRole(role.id); }));
            }
        }
    };
    AccountService.prototype.getPermissions = function () {
        return this.accountEndpoint.getPermissionsEndpoint();
    };
    AccountService.prototype.onRolesChanged = function (roles, op) {
        this._rolesChanged.next({ roles: roles, operation: op });
    };
    AccountService.prototype.onRolesUserCountChanged = function (roles) {
        return this.onRolesChanged(roles, AccountService.roleModifiedOperation);
    };
    AccountService.prototype.getRolesChangedEvent = function () {
        return this._rolesChanged.asObservable();
    };
    Object.defineProperty(AccountService.prototype, "permissions", {
        get: function () {
            return this.authServiceS.userPermissions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountService.prototype, "currentUser", {
        get: function () {
            return this.authServiceS.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    AccountService.roleAddedOperation = "add";
    AccountService.roleDeletedOperation = "delete";
    AccountService.roleModifiedOperation = "modify";
    return AccountService;
}(EndpointFactory));
export { AccountService };
