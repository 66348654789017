import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { GlobalUtilitiesProvider } from '../global-utilities.provider';

// You can use a Buffer, too
@Injectable()
export class UploadFileService {
  idUsuario: string = "";
  dataAux = new Date();
  FOLDER = (this.vGlobal._usuario) ? 'UsuariosMinhaConta/' + (this.vGlobal._usuario.Id) + '/' : "";

  constructor(private vGlobal: GlobalUtilitiesProvider) {
    if (this.vGlobal) {
      if (this.vGlobal._usuario) {
        this.idUsuario = this.vGlobal._usuario.Id;
      }
    }
  }

  uploadfile(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const bucket = new S3(
        {
          accessKeyId: 'AKIAJNFV7HZE7U5ZNUZQ',
          secretAccessKey: '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',
          region: 'sa-east-1'
        }
      );
      const params = {
        Bucket: 'elasticbeanstalk-sa-east-1-603429834916',
        Key: this.FOLDER + this.dataAux.getTime(),
        Body: file,
        ACL: 'public-read'
      };

      bucket.upload(params, function (err, data) {
        if (err) {
          reject('');
        }
        let fotoAux = `https://s3-sa-east-1.amazonaws.com/elasticbeanstalk-sa-east-1-603429834916/${params.Key}`;
        resolve(fotoAux);

      });
    });

  }












}
