import { AlertService, DialogType, MessageSeverity } from '../services/alert.service';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { AppTitleService } from '../services/app-title.service';
import { AuthServices } from '../services/auth.service';
import { UserPerfil } from "../models/user.perfil";
import { GlobalUtilitiesProvider } from "../providers/global-utilities.provider";
import { OnInit, QueryList, OnDestroy } from "@angular/core";
import { ToastaService, ToastOptions, ToastData } from 'ngx-toasta';
import { Router, NavigationStart } from '@angular/router';
import { Utilities } from '../services/utilities';
var alertify = require('../assets/scripts/alertify.js');
var AppComponent = /** @class */ (function () {
    //#endregion
    function AppComponent(storageManager, toastaService, alertService, appTitleService, authService, router, variaveisGlobais) {
        this.toastaService = toastaService;
        this.alertService = alertService;
        this.appTitleService = appTitleService;
        this.authService = authService;
        this.router = router;
        this.variaveisGlobais = variaveisGlobais;
        //#region Variaveis
        this.usuario = new UserPerfil();
        this.newNotificationCount = 0;
        this.appTitle = "ListaMaisApp";
        this.appLogo = require("../assets/images/logo-white.png");
        this.stickyToasties = [];
        this.dataLoadingConsecutiveFailurs = 0;
        this.display = 'none';
        this._imageLogo = '/assets/theme/img/logo-listamais-y.svg';
        this._styleLogo = '80%';
        this._styleContainerLogo = '62%';
        this._styleLogoMobile = '70%';
        storageManager.initialiseStorageSyncListener();
        this.appTitleService.appName = this.appTitle;
    }
    AppComponent.prototype.onLoadAccount = function () {
        this.variaveisGlobais._displayButton = 'block';
        this.variaveisGlobais._logado = true;
        this.usuario = this.variaveisGlobais.ReturnUser();
    };
    AppComponent.prototype.stopLoading = function () {
        this.alertService.stopLoadingMessage();
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isUserLoggedIn = this.authService.isLoggedIn;
        this.isAppLoaded = true;
        this.removePrebootScreen = true;
        setTimeout(function () { return _this.isAppLoaded = true; }, 500);
        setTimeout(function () { return _this.removePrebootScreen = true; }, 700);
        if (this.isUserLoggedIn) {
            this.onLoadAccount();
            this.alertService.resetStickyMessage();
            if (!this.authService.isSessionExpired) {
                this.usuario = this.variaveisGlobais.ReturnUser();
            }
        }
        setTimeout(function () {
            if (_this.isUserLoggedIn) {
                _this.onLoadAccount();
                _this.alertService.resetStickyMessage();
                if (!_this.authService.isSessionExpired) {
                    _this.usuario = _this.variaveisGlobais.ReturnUser();
                }
            }
        }, 500);
        this.alertService.getDialogEvent().subscribe(function (alert) { return _this.showDialog(alert); });
        this.alertService.getMessageEvent().subscribe(function (message) { return _this.showToast(message, false); });
        this.alertService.getStickyMessageEvent().subscribe(function (message) { return _this.showToast(message, true); });
        this.authService.reLoginDelegate = function () { return _this.shouldShowLoginModal = true; };
        this.authService.getLoginStatusEvent().subscribe(function (isLoggedIn) {
            _this.isUserLoggedIn = isLoggedIn;
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                var url = event.url;
                if (url !== url.toLowerCase()) {
                    _this.router.navigate([event.url.toLowerCase()]);
                }
            }
        });
    };
    AppComponent.prototype.PintaBloco = function () {
        document.getElementById("empresa-botao").className = "active";
    };
    AppComponent.prototype.ngOnDestroy = function () {
        localStorage.clear();
    };
    AppComponent.prototype.Open = function () {
        this.display = 'block';
    };
    AppComponent.prototype.Close = function () {
        this.display = 'none';
    };
    AppComponent.prototype.showDialog = function (dialog) {
        alertify.set({
            labels: {
                ok: dialog.okLabel || "OK",
                cancel: dialog.cancelLabel || "Cancelar"
            }
        });
        switch (dialog.type) {
            case DialogType.alert:
                alertify.alert(dialog.message);
                break;
            case DialogType.confirm:
                alertify
                    .confirm(dialog.message, function (e) {
                    if (e) {
                        dialog.okCallback();
                    }
                    else {
                        if (dialog.cancelCallback)
                            dialog.cancelCallback();
                    }
                });
                break;
            case DialogType.prompt:
                alertify
                    .prompt(dialog.message, function (e, val) {
                    if (e) {
                        dialog.okCallback(val);
                    }
                    else {
                        if (dialog.cancelCallback)
                            dialog.cancelCallback();
                    }
                }, dialog.defaultValue);
                break;
        }
    };
    AppComponent.prototype.showToast = function (message, isSticky) {
        var _this = this;
        if (message == null) {
            for (var _i = 0, _a = this.stickyToasties.slice(0); _i < _a.length; _i++) {
                var id = _a[_i];
                this.toastaService.clear(id);
            }
            return;
        }
        var toastOptions = {
            title: message.summary,
            msg: message.detail,
            timeout: isSticky ? 0 : 4000
        };
        if (isSticky) {
            toastOptions.onAdd = function (toast) { return _this.stickyToasties.push(toast.id); };
            toastOptions.onRemove = function (toast) {
                var index = _this.stickyToasties.indexOf(toast.id, 0);
                if (index > -1) {
                    _this.stickyToasties.splice(index, 1);
                }
                toast.onAdd = null;
                toast.onRemove = null;
            };
        }
        switch (message.severity) {
            case MessageSeverity.default:
                this.toastaService.default(toastOptions);
                break;
            case MessageSeverity.info:
                this.toastaService.info(toastOptions);
                break;
            case MessageSeverity.success:
                this.toastaService.success(toastOptions);
                break;
            case MessageSeverity.error:
                this.toastaService.error(toastOptions);
                break;
            case MessageSeverity.warn:
                this.toastaService.warning(toastOptions);
                break;
            case MessageSeverity.wait:
                this.toastaService.wait(toastOptions);
                break;
        }
    };
    AppComponent.prototype.voltarAoSite = function () {
        this.verificaEmpresaEdicao('https://www.listamais.com.br', false);
    };
    AppComponent.prototype.IrParaHome = function () {
        this.router.navigate(['/home']);
    };
    AppComponent.prototype.verificaEmpresaEdicao = function (site, tipo) {
        var _this = this;
        var usuEmp = JSON.parse(localStorage.getItem('empresaEdicao'));
        if (usuEmp) {
            Utilities.SwalWarningChoises('Atenção!', 'Tinha uma empresa em edição, deseja terminar o cadastro antes de sair?', 'Sim!', 'Não, pode apagar!').then(function (result) {
                if (result.value) {
                    _this.router.navigate(['/empresa/cadastro']);
                }
                else {
                    localStorage.removeItem('empresaEdicao');
                    if (tipo) {
                        // this.variaveisGlobais._logado = false;
                        // this.variaveisGlobais._displayButton = 'none';
                        // localStorage.removeItem('USUARIOLOGADO');
                        // this.authService.logout();
                        // localStorage.clear();
                        window.location.href = site;
                    }
                    else {
                        setTimeout(function () {
                            window.location.replace(site);
                        }, 100);
                    }
                }
            });
        }
        else {
            // this.variaveisGlobais._logado = false;
            // this.variaveisGlobais._displayButton = 'none';
            // localStorage.removeItem('USUARIOLOGADO');
            // this.authService.logout();
            localStorage.removeItem('empresaEdicao');
            setTimeout(function () {
                window.location.replace(site);
            }, 100);
        }
    };
    AppComponent.prototype.logoutTrocaEmail = function () {
        this.variaveisGlobais._logado = false;
        this.variaveisGlobais._displayButton = 'none';
        localStorage.clear();
        localStorage.removeItem('USUARIOLOGADO');
        this.authService.logout();
    };
    AppComponent.prototype.logout = function () {
        this.variaveisGlobais._logado = false;
        this.variaveisGlobais._displayButton = 'none';
        localStorage.clear();
        localStorage.removeItem('USUARIOLOGADO');
        this.authService.logout();
        setTimeout(function () {
            window.location.replace("https://www.listamais.com.br");
        }, 100);
    };
    AppComponent.prototype.logoutMinhaConta = function () {
        this.variaveisGlobais._logado = false;
        this.variaveisGlobais._displayButton = 'none';
        localStorage.clear();
        localStorage.removeItem('USUARIOLOGADO');
        this.authService.logout();
    };
    AppComponent.prototype.logoutSiteIntegrado = function () {
        this.verificaEmpresaEdicao('https://www.listamais.com.br/MinhaConta/SairMinhaConta', true);
    };
    AppComponent.prototype.getYear = function () {
        return new Date().getUTCFullYear();
    };
    Object.defineProperty(AppComponent.prototype, "userName", {
        get: function () {
            return this.authService.currentUser ? this.authService.currentUser.userName : "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "fullName", {
        get: function () {
            return this.authService.currentUser ? this.authService.currentUser.fullName : "";
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.GravarRotaMinhaConta = function () {
        this.variaveisGlobais.SaveLastRoute('minha-conta');
    };
    return AppComponent;
}());
export { AppComponent };
